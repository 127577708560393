var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.construction
    ? _c("p", [
        _setup.construction.value >= 0
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(parseFloat(_setup.construction.value).toFixed(2)) +
                  " (" +
                  _vm._s(_setup.construction.category) +
                  ") "
              ),
            ])
          : _c("span", [
              _vm._v(
                " " +
                  _vm._s(_setup.construction.category) +
                  " (" +
                  _vm._s(
                    Math.abs(parseFloat(_setup.construction.value).toFixed(2))
                  ) +
                  ") "
              ),
            ]),
      ])
    : _c("p", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }