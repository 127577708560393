<script lang="ts" setup>
import { ref, computed, watch } from "vue";

import { getBuilding, severityCode } from "@/services/api/building.api";
import LocationMap from "@/components/general/LocationMap.vue";
import ConstructionCategory from "@/components/general/ConstructionCategory.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useMessages from "@/composables/messages.js";

const { formatDateUtc } = useDateTimeFormatter();
const { msgDataScore } = useMessages();

const props = defineProps({
  addressId: {
    type: String,
    default: "",
  },
});

const refreshAppliedKey = ref(0);
const errored = ref(false);
const error = ref(null);
const isLoading = ref(false);
const showUsageDetails = ref(false);

const building = ref({
  locationReference: "",
  description: "",
  address: "",
  number: "",
  postalCode: "",
  city: "",
  ecmFunctionCode: undefined,
  ecmFunctionName: "",
  weiiBuildingDecree: "",
  weiiBuildingCategory: "",
  squareMeters: "",
  dataScore: {
    percent: undefined,
    messages: [],
  },
  parisProofScore: undefined,
  parisProofNorm: undefined,
  energyLabelIsEstimate: false,
  country: "",
  code: "",
  energyLabel: "-",
  operationalLabel: "-",
  colorLabel: "-",
  colorOperationalLabel: "-",
  energyIndex: "",
  energyDemand: "",
  primaryFossilEnergyUse: "",
  shareOfRenewableEnergy: "",
  labelSource: "",
  bagBld: "",
  bagObj: "",
  buildYear: "",
  occupRate: "",
  hourOfUse: "",
  usageTime: "",
  usageStatus: "",
  gbsType: "",
  openingDate: "",
  closingDate: "",
  energySources: "",
  eedDate: "",
  bspRealised: "",
  lat: 0,
  lon: 0,
  mapdata: "",
  energetics: {
    parts: [],
  },
  isMonumental: "",
  isRenewEnergyUsed: "",
  shareOfRenewElecPerc: "",
  shareOfRenewEnergyPerc: "",
  shareOfRenewHeatPerc: "",
  installations: {
    ventType: "",
    humidType: "",
    heatType: "",
    coolType: "",
    warmwatType: "",
    manageSys: "",
    lightType: "",
    avgWattLights: 0,
    solarAmount: "",
    solarType: "",
  },
});

const hasDataAvailabilityMessages = computed(() => building.value.dataScore && building.value.dataScore.messages.length > 0);
const dataScoreMessages = computed(() => (building.value.dataScore ? building.value.dataScore.messages : undefined));
const dataScorePercent = computed(() => (building.value.dataScore ? building.value.dataScore.percent : undefined));

watch(
  () => props.addressId,
  () => {
    fetchData();
  },
  { immediate: true }
);

async function fetchData() {
  isLoading.value = true;
  const response = await getBuilding(props.addressId);
  console.log("BuildingDetails fetchData ", response);
  if (response.severity <= severityCode.warning) {
    building.value = response.data;
  } else {
    errored.value = true;
    error.value = response.error;
  }
  isLoading.value = false;
}

function handleRerenderBuildingAppliedOverview(measuresAdded) {
  refreshAppliedKey.value++;
  fetchData(); // ToDo: Hier wordt nu alles opnieuw opgehaald om de widget, tonen button en maatregelengrid opnieuw op te bouwen, eigenlijk moet dit veel specifieker, alleen de score, alleen de conditie voor de button en alleen de maatregelengrid
  if (measuresAdded) {
    alert("De erkende maatregelen zijn toegevoegd.");
  } else {
    alert("Er zijn geen erkende maatregelen toegevoegd.");
  }
}
</script>

<template>
  <v-container fluid pa-0 fill-height>
    <v-row>
      <v-col lg="3" md="4" sm="12" order="2" order-md="1" class="app-paris-proof-sidebar">
        <building-paris-proof-score :addressId="addressId" :dataScore="dataScoreMessages"></building-paris-proof-score>
      </v-col>
      <v-col v-if="!errored" lg="9" md="8" sm="12" order="1" order-md="2" class="app-building-detail">
        <v-row>
          <v-col cols="12" lg="7" class="building-info">
            <v-container class="inner">
              <header>
                <h1 class="small">{{ building.description }}</h1>
                <strong>{{ building.ecmFunctionName }}</strong>
              </header>
              <v-row>
                <v-col>
                  <ul class="building-details">
                    <li>{{ building.address }}</li>
                    <li>{{ building.postalCode }} {{ building.city }}</li>
                    <li>{{ building.country }}</li>
                  </ul>
                  <v-container pa-0>
                    <v-row noGutters class="energy-scores">
                      <v-col>
                        <p>Operationeel</p>
                        <energy-label :label="building.operationalLabel" :estimate="building.energyLabelIsEstimate"></energy-label>
                      </v-col>

                      <v-col>
                        <p>Energielabel</p>
                        <energy-label :label="building.energyLabel" :estimate="building.energyLabelIsEstimate"></energy-label>
                      </v-col>

                      <!-- het circeltje van de widget ontbreekt als er een tooltip wordt gebruikt -->
                      <v-col>
                        <p>Datascore</p>
                        <div v-show="isLoading === false">
                          <div v-show="hasDataAvailabilityMessages">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <div v-on="on" class="widget-chart building-data-score" style="cursor: help">
                                  <percentage-chart :width="68" :height="68" :percentage="dataScorePercent" />
                                </div>
                              </template>
                              <div>
                                <p>Dit ontbreekt er nog</p>
                                <ul>
                                  <li v-for="(msg, index) in dataScoreMessages" :key="index">{{ msgDataScore(msg) }}<br /></li>
                                </ul>
                              </div>
                            </v-tooltip>
                          </div>
                          <div v-show="!hasDataAvailabilityMessages" class="widget-chart building-data-score">
                            <percentage-chart :width="68" :height="68" :percentage="dataScorePercent" />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col col="5">
                  <loader-spinner v-if="isLoading === true" :is-loading="isLoading"></loader-spinner>
                  <location-map v-else-if="building.mapdata" :locationmapdata="building.mapdata"></location-map>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col cols="12" :lg="showUsageDetails ? 12 : 5" class="usages">
            <v-container fluid class="inner">
              <building-yearly-usages :addressId="props.addressId" v-model="showUsageDetails"></building-yearly-usages>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="general-details">
            <v-container fluid class="inner">
              <header>
                <h2>Algemene gegevens</h2>
                <div class="mr-auto mt-n3">
                  <loader-spinner v-if="isLoading === true" :is-loading="isLoading"></loader-spinner>
                </div>
                <div class="widget-actions">
                  <information-dropdown>
                    <template v-slot:header>
                      <h2>Gebouw informatie</h2>
                    </template>
                    <p>
                      Een gebouw bestaat uit veel verschillende soorten informatie, de Paris Proof Monitor toont hier de belangrijkste eigenschappen van uw gebouw. Dit wordt onderverdeeld in 3
                      tabbladen:
                    </p>

                    <p></p>
                    <h4>Algemene gegevens</h4>
                    <p>
                      Hier worden de algemene gegevens van uw pand getoond. Deze informatie komt uit meerdere bronnen: EP-Online, BAG (Kadaster) en de door u aangeleverde informatie en/of databronnen.
                    </p>
                    <h4>Bouwkundig</h4>
                    <p>Hier worden de gemiddelde isolatiewaarden van uw gebouw getoond. Dit doen we vaak middels de EPA-maatwerkadviezen, EED-onderzoeken en/of energielabels die gemaakt zijn.</p>
                    <h4>Installatiegegevens</h4>
                    <p>
                      Wat voor installaties zitten er in het gebouw? Hier ziet u welke installaties er in het pand aanwezig zijn. De getoonde gegevens zijn afkomstig uit het bronbestand van EPA en/of
                      de door u aangeleverde informatie.
                    </p>
                    <h4>Overige</h4>
                    <p>Hier worden de overige gegevens van uw pand getoond, bijv. gegevens t.b.v. de Informatieplicht.</p>
                  </information-dropdown>
                </div>
              </header>
              <v-tabs class="tabToggles" active-class="active">
                <v-tabs-slider hidden></v-tabs-slider>
                <v-tab class="tab" style="width: 100%">Algemene gegevens</v-tab>
                <v-tab class="tab" style="width: 100%">Bouwkundig</v-tab>
                <v-tab class="tab" style="width: 100%">Installatie</v-tab>
                <v-tab class="tab" style="width: 100%">Overige</v-tab>
                <v-tab-item style="margin: 10px">
                  <v-container pa-0>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Omschrijving</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.description }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Bouwjaar</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.buildYear }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Functie</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.ecmFunctionName }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gebruiksstatus</strong>
                            </v-col>
                            <v-col cols="4">
                              <p>{{ building.usageStatus }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>WEii Bouwbesluit</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.weiiBuildingDecree }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>GO</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.squareMeters }} m²</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>WEii Klasse</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.weiiBuildingCategory }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>GBS</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.gbsType }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5">
                              <strong>Bezettingsgraad</strong>
                            </v-col>
                            <v-col cols="6">
                              <p>{{ building.occupRate }}%</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Maatwerkadvies uitgevoerd</strong>
                            </v-col>
                            <v-col cols="4">
                              <p>{{ building.bspRealised }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Datum opening</strong></v-col>
                            <v-col cols="6">
                              <p>{{ formatDateUtc(building.openingDate) }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>EED uitgevoerd</strong></v-col>
                            <v-col cols="4">
                              <p>{{ formatDateUtc(building.eedDate) }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Datum sluiting</strong></v-col>
                            <v-col cols="6">
                              <p>{{ formatDateUtc(building.closingDate) }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Energie-index</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.energyIndex }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Bedrijfstijden (uren/dag)</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.usageTime }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Herkomst</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.labelSource }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Bedrijfstijden (uren/week)</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.hourOfUse }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row"> </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>Gebouwcode</strong></v-col>
                            <v-col cols="6">
                              <p>{{ building.code }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Energiebehoefte (BENG1)</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.energyDemand }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5"><strong>BAG-gebouw</strong></v-col>
                            <v-col cols>
                              <p>{{ building.bagBld }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Primaire fossiele energie (BENG2)</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.primaryFossilEnergyUse }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5">
                              <strong>BAG-verblijfsobject</strong>
                            </v-col>
                            <v-col cols>
                              <p>{{ building.bagObj }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Aandeel hernieuwbare energie (BENG3)</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.shareOfRenewableEnergy }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5">
                              <strong>INNAX-ID</strong>
                            </v-col>
                            <v-col cols>
                              <p>{{ building.locationReference }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7"><strong>Rol</strong></v-col>
                            <v-col cols="4">
                              <p>{{ building.partyRoleDescription }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="5">
                              <strong>Klantreferentie</strong>
                            </v-col>
                            <v-col cols>
                              <p>{{ building.reference }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row"> </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item style="margin: 10px">
                  <v-container pa-0>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde dak</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Roof" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde vloer</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Floor" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde gevel</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Frontage" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde panelen</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Panel" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde glas (U)</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Glass" insulationType="U" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Gemiddelde isolatiewaarde glas (ZTA)</strong>
                            </v-col>
                            <v-col cols="5">
                              <ConstructionCategory code="Glass" insulationType="G" :parts="building.energetics?.parts"></ConstructionCategory>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="7">
                              <strong>Brongegevens</strong>
                            </v-col>
                            <v-col cols="5">
                              <p>{{ building.energetics?.source }}</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item style="margin: 10px">
                  <v-container pa-0>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type ventilatie</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.ventType">
                                {{ building.installations.ventType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type bevochtiging</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.humidType">
                                {{ building.installations.humidType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type verwarming</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.heatType">
                                {{ building.installations.heatType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6"> <strong>Type koeling</strong></v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.coolType">
                                {{ building.installations.coolType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type (warm) tapwater</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.warmwatType">
                                {{ building.installations.warmwatType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type verlichting</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.lightType">
                                {{ building.installations.lightType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Gemiddeld verlichtingsvermogen</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.avgWattLights">
                                {{ parseFloat(building.installations.avgWattLights).toFixed(2) }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Type zonnepanelen</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.solarType">
                                {{ building.installations.solarType }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Aantal zonnepanelen</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.solarAmount">
                                {{ building.installations.solarAmount }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <strong>Brongegevens</strong>
                            </v-col>
                            <v-col cols="6">
                              <p v-if="building.installations?.source">
                                {{ building.installations.source }}
                              </p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item style="margin: 10px">
                  <v-container pa-0>
                    <v-row>
                      <v-col cols="5" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="8">
                              <strong>Monument</strong>
                            </v-col>
                            <v-col cols="2">
                              <p>
                                {{ building.isMonumental === true ? "Ja" : "Nee" }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="8">
                              <strong>Hernieuwbare energie?</strong>
                            </v-col>
                            <v-col cols="2">
                              <p>
                                {{ building.isRenewEnergyUsed === true ? "Ja" : "Nee" }}
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="8">
                              <strong>Percentage hernieuwbare elektriciteit</strong>
                            </v-col>
                            <v-col cols="2">
                              <p v-if="building.shareOfRenewElecPerc">{{ building.shareOfRenewElecPerc }} %</p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="8">
                              <strong>Percentage hernieuwbare energie</strong>
                            </v-col>
                            <v-col cols="2">
                              <p v-if="building.shareOfRenewEnergyPerc">{{ building.shareOfRenewEnergyPerc }} %</p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="8">
                              <strong>Percentage hernieuwbare warmte</strong>
                            </v-col>
                            <v-col cols="2">
                              <p v-if="building.shareOfRenewHeatPerc">{{ building.shareOfRenewHeatPerc }} %</p>
                              <p v-else>-</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>

                      <v-col cols="7" class="content-row">
                        <v-container>
                          <v-row>
                            <v-col cols="3">
                              <header>
                                <strong>Contactperso(o)n(en):</strong>
                              </header>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="1">
                              <header style="margin-top: 4px">
                                <strong>Locatie</strong>
                              </header>
                            </v-col>
                            <v-col v-if="building.buildingContacts && building.buildingContacts.length > 0">
                              <v-container class="boxed">
                                <v-row v-for="(item, index) in building.buildingContacts" v-bind:key="index">
                                  <v-col cols="4">{{ item.partyRoleDescription }}</v-col>
                                  <v-col cols="4">{{ item.userDescription }}</v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                            <v-col v-else>
                              <v-col>-</v-col>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="1">
                              <header style="margin-top: 4px">
                                <strong>Extern</strong>
                              </header>
                            </v-col>
                            <v-col v-if="building.partyContacts && building.partyContacts.length > 0">
                              <v-container class="boxed">
                                <v-row v-for="(item, index) in building.partyContacts" v-bind:key="index">
                                  <v-col cols="4">{{ item.partyRoleDescription }}</v-col>
                                  <v-col cols="4">{{ item.userDescription }}</v-col>
                                  <v-col cols="4" v-if="item.companyDescription">{{ item.companyDescription }}</v-col>
                                  <v-col v-else>-</v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                            <v-col v-else>
                              <v-col>-</v-col>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="overview-wrapper">
          <v-col>
            <building-applied-overview
              :addressId="addressId"
              :ecmFunctionCode="building.ecmFunctionCode"
              :ecmFunctionName="building.ecmFunctionName"
              :key="refreshAppliedKey"
              @rerender-building-applied-overview="handleRerenderBuildingAppliedOverview"
            >
            </building-applied-overview>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else class="app-building-detail">
        <header>
          <h1>U bent niet geautoriseerd voor deze pagina.</h1>
          <h3>{{ error }}</h3>
        </header>
      </v-col>
    </v-row>
  </v-container>
</template>
