var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "fill-height": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "app-paris-proof-sidebar",
              attrs: {
                lg: "3",
                md: "4",
                sm: "12",
                order: "2",
                "order-md": "1",
              },
            },
            [
              _c("building-paris-proof-score", {
                attrs: {
                  addressId: _vm.addressId,
                  dataScore: _setup.dataScoreMessages,
                },
              }),
            ],
            1
          ),
          !_setup.errored
            ? _c(
                "v-col",
                {
                  staticClass: "app-building-detail",
                  attrs: {
                    lg: "9",
                    md: "8",
                    sm: "12",
                    order: "1",
                    "order-md": "2",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "building-info",
                          attrs: { cols: "12", lg: "7" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "inner" },
                            [
                              _c("header", [
                                _c("h1", { staticClass: "small" }, [
                                  _vm._v(_vm._s(_setup.building.description)),
                                ]),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_setup.building.ecmFunctionName)
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "building-details" },
                                        [
                                          _c("li", [
                                            _vm._v(
                                              _vm._s(_setup.building.address)
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              _vm._s(
                                                _setup.building.postalCode
                                              ) +
                                                " " +
                                                _vm._s(_setup.building.city)
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              _vm._s(_setup.building.country)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-container",
                                        { attrs: { "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "energy-scores",
                                              attrs: { noGutters: "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("p", [
                                                    _vm._v("Operationeel"),
                                                  ]),
                                                  _c("energy-label", {
                                                    attrs: {
                                                      label:
                                                        _setup.building
                                                          .operationalLabel,
                                                      estimate:
                                                        _setup.building
                                                          .energyLabelIsEstimate,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("p", [
                                                    _vm._v("Energielabel"),
                                                  ]),
                                                  _c("energy-label", {
                                                    attrs: {
                                                      label:
                                                        _setup.building
                                                          .energyLabel,
                                                      estimate:
                                                        _setup.building
                                                          .energyLabelIsEstimate,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("v-col", [
                                                _c("p", [_vm._v("Datascore")]),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _setup.isLoading ===
                                                          false,
                                                        expression:
                                                          "isLoading === false",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _setup.hasDataAvailabilityMessages,
                                                            expression:
                                                              "hasDataAvailabilityMessages",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              right: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "widget-chart building-data-score",
                                                                            staticStyle:
                                                                              {
                                                                                cursor:
                                                                                  "help",
                                                                              },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "percentage-chart",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  width: 68,
                                                                                  height: 68,
                                                                                  percentage:
                                                                                    _setup.dataScorePercent,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              950447307
                                                            ),
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c("p", [
                                                                _vm._v(
                                                                  "Dit ontbreekt er nog"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "ul",
                                                                _vm._l(
                                                                  _setup.dataScoreMessages,
                                                                  function (
                                                                    msg,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _setup.msgDataScore(
                                                                              msg
                                                                            )
                                                                          )
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              !_setup.hasDataAvailabilityMessages,
                                                            expression:
                                                              "!hasDataAvailabilityMessages",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "widget-chart building-data-score",
                                                      },
                                                      [
                                                        _c("percentage-chart", {
                                                          attrs: {
                                                            width: 68,
                                                            height: 68,
                                                            percentage:
                                                              _setup.dataScorePercent,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { col: "5" } },
                                    [
                                      _setup.isLoading === true
                                        ? _c("loader-spinner", {
                                            attrs: {
                                              "is-loading": _setup.isLoading,
                                            },
                                          })
                                        : _setup.building.mapdata
                                        ? _c(_setup.LocationMap, {
                                            attrs: {
                                              locationmapdata:
                                                _setup.building.mapdata,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "usages",
                          attrs: {
                            cols: "12",
                            lg: _setup.showUsageDetails ? 12 : 5,
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "inner", attrs: { fluid: "" } },
                            [
                              _c("building-yearly-usages", {
                                attrs: { addressId: _setup.props.addressId },
                                model: {
                                  value: _setup.showUsageDetails,
                                  callback: function ($$v) {
                                    _setup.showUsageDetails = $$v
                                  },
                                  expression: "showUsageDetails",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "general-details",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "inner", attrs: { fluid: "" } },
                            [
                              _c("header", [
                                _c("h2", [_vm._v("Algemene gegevens")]),
                                _c(
                                  "div",
                                  { staticClass: "mr-auto mt-n3" },
                                  [
                                    _setup.isLoading === true
                                      ? _c("loader-spinner", {
                                          attrs: {
                                            "is-loading": _setup.isLoading,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "widget-actions" },
                                  [
                                    _c(
                                      "information-dropdown",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c("h2", [
                                                    _vm._v("Gebouw informatie"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          847033098
                                        ),
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " Een gebouw bestaat uit veel verschillende soorten informatie, de Paris Proof Monitor toont hier de belangrijkste eigenschappen van uw gebouw. Dit wordt onderverdeeld in 3 tabbladen: "
                                          ),
                                        ]),
                                        _c("p"),
                                        _c("h4", [_vm._v("Algemene gegevens")]),
                                        _c("p", [
                                          _vm._v(
                                            " Hier worden de algemene gegevens van uw pand getoond. Deze informatie komt uit meerdere bronnen: EP-Online, BAG (Kadaster) en de door u aangeleverde informatie en/of databronnen. "
                                          ),
                                        ]),
                                        _c("h4", [_vm._v("Bouwkundig")]),
                                        _c("p", [
                                          _vm._v(
                                            "Hier worden de gemiddelde isolatiewaarden van uw gebouw getoond. Dit doen we vaak middels de EPA-maatwerkadviezen, EED-onderzoeken en/of energielabels die gemaakt zijn."
                                          ),
                                        ]),
                                        _c("h4", [
                                          _vm._v("Installatiegegevens"),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " Wat voor installaties zitten er in het gebouw? Hier ziet u welke installaties er in het pand aanwezig zijn. De getoonde gegevens zijn afkomstig uit het bronbestand van EPA en/of de door u aangeleverde informatie. "
                                          ),
                                        ]),
                                        _c("h4", [_vm._v("Overige")]),
                                        _c("p", [
                                          _vm._v(
                                            "Hier worden de overige gegevens van uw pand getoond, bijv. gegevens t.b.v. de Informatieplicht."
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-tabs",
                                {
                                  staticClass: "tabToggles",
                                  attrs: { "active-class": "active" },
                                },
                                [
                                  _c("v-tabs-slider", {
                                    attrs: { hidden: "" },
                                  }),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab",
                                      staticStyle: { width: "100%" },
                                    },
                                    [_vm._v("Algemene gegevens")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab",
                                      staticStyle: { width: "100%" },
                                    },
                                    [_vm._v("Bouwkundig")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab",
                                      staticStyle: { width: "100%" },
                                    },
                                    [_vm._v("Installatie")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab",
                                      staticStyle: { width: "100%" },
                                    },
                                    [_vm._v("Overige")]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticStyle: { margin: "10px" } },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Omschrijving"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .description
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Bouwjaar"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .buildYear
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Functie"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .ecmFunctionName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gebruiksstatus"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .usageStatus
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "WEii Bouwbesluit"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .weiiBuildingDecree
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("GO"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .squareMeters
                                                                  ) + " m²"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "WEii Klasse"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .weiiBuildingCategory
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("GBS"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .gbsType
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Bezettingsgraad"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .occupRate
                                                                  ) + "%"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Maatwerkadvies uitgevoerd"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .bspRealised
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Datum opening"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup.formatDateUtc(
                                                                      _setup
                                                                        .building
                                                                        .openingDate
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "EED uitgevoerd"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup.formatDateUtc(
                                                                      _setup
                                                                        .building
                                                                        .eedDate
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Datum sluiting"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup.formatDateUtc(
                                                                      _setup
                                                                        .building
                                                                        .closingDate
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Energie-index"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .energyIndex
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Bedrijfstijden (uren/dag)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .usageTime
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Herkomst"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .labelSource
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Bedrijfstijden (uren/week)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .hourOfUse
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                staticClass: "content-row",
                                                attrs: { cols: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gebouwcode"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .code
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Energiebehoefte (BENG1)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .energyDemand
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "BAG-gebouw"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .bagBld
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Primaire fossiele energie (BENG2)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .primaryFossilEnergyUse
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "BAG-verblijfsobject"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .bagObj
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Aandeel hernieuwbare energie (BENG3)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .shareOfRenewableEnergy
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "INNAX-ID"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .locationReference
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("Rol"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .partyRoleDescription
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Klantreferentie"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .reference
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-col", {
                                                staticClass: "content-row",
                                                attrs: { cols: "6" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticStyle: { margin: "10px" } },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde dak"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Roof",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde vloer"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Floor",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde gevel"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Frontage",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde panelen"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Panel",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde glas (U)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Glass",
                                                                    insulationType:
                                                                      "U",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddelde isolatiewaarde glas (ZTA)"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.ConstructionCategory,
                                                                {
                                                                  attrs: {
                                                                    code: "Glass",
                                                                    insulationType:
                                                                      "G",
                                                                    parts:
                                                                      _setup
                                                                        .building
                                                                        .energetics
                                                                        ?.parts,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Brongegevens"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _setup
                                                                      .building
                                                                      .energetics
                                                                      ?.source
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticStyle: { margin: "10px" } },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type ventilatie"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.ventType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .ventType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type bevochtiging"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.humidType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .humidType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type verwarming"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.heatType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .heatType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type koeling"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.coolType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .coolType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type (warm) tapwater"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.warmwatType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .warmwatType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type verlichting"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.lightType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .lightType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Gemiddeld verlichtingsvermogen"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.avgWattLights
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            _setup
                                                                              .building
                                                                              .installations
                                                                              .avgWattLights
                                                                          ).toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Type zonnepanelen"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.solarType
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .solarType
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Aantal zonnepanelen"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.solarAmount
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .solarAmount
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Brongegevens"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .installations
                                                                ?.source
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup
                                                                            .building
                                                                            .installations
                                                                            .source
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticStyle: { margin: "10px" } },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "5" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Monument"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _setup
                                                                        .building
                                                                        .isMonumental ===
                                                                        true
                                                                        ? "Ja"
                                                                        : "Nee"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Hernieuwbare energie?"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _setup
                                                                        .building
                                                                        .isRenewEnergyUsed ===
                                                                        true
                                                                        ? "Ja"
                                                                        : "Nee"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Percentage hernieuwbare elektriciteit"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .shareOfRenewElecPerc
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _setup
                                                                          .building
                                                                          .shareOfRenewElecPerc
                                                                      ) + " %"
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Percentage hernieuwbare energie"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .shareOfRenewEnergyPerc
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _setup
                                                                          .building
                                                                          .shareOfRenewEnergyPerc
                                                                      ) + " %"
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Percentage hernieuwbare warmte"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _setup.building
                                                                .shareOfRenewHeatPerc
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _setup
                                                                          .building
                                                                          .shareOfRenewHeatPerc
                                                                      ) + " %"
                                                                    ),
                                                                  ])
                                                                : _c("p", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "content-row",
                                                  attrs: { cols: "7" },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("header", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Contactperso(o)n(en):"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "header",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      "Locatie"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _setup.building
                                                            .buildingContacts &&
                                                          _setup.building
                                                            .buildingContacts
                                                            .length > 0
                                                            ? _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    {
                                                                      staticClass:
                                                                        "boxed",
                                                                    },
                                                                    _vm._l(
                                                                      _setup
                                                                        .building
                                                                        .buildingContacts,
                                                                      function (
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-row",
                                                                          {
                                                                            key: index,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.partyRoleDescription
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.userDescription
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-col",
                                                                [
                                                                  _c("v-col", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "header",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "4px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      "Extern"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _setup.building
                                                            .partyContacts &&
                                                          _setup.building
                                                            .partyContacts
                                                            .length > 0
                                                            ? _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    {
                                                                      staticClass:
                                                                        "boxed",
                                                                    },
                                                                    _vm._l(
                                                                      _setup
                                                                        .building
                                                                        .partyContacts,
                                                                      function (
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "v-row",
                                                                          {
                                                                            key: index,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.partyRoleDescription
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.userDescription
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            item.companyDescription
                                                                              ? _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "4",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.companyDescription
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "v-col",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "-"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-col",
                                                                [
                                                                  _c("v-col", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "overview-wrapper" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("building-applied-overview", {
                            key: _setup.refreshAppliedKey,
                            attrs: {
                              addressId: _vm.addressId,
                              ecmFunctionCode: _setup.building.ecmFunctionCode,
                              ecmFunctionName: _setup.building.ecmFunctionName,
                            },
                            on: {
                              "rerender-building-applied-overview":
                                _setup.handleRerenderBuildingAppliedOverview,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-col", { staticClass: "app-building-detail" }, [
                _c("header", [
                  _c("h1", [
                    _vm._v("U bent niet geautoriseerd voor deze pagina."),
                  ]),
                  _c("h3", [_vm._v(_vm._s(_setup.error))]),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }