<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  code: {
    type: String,
  },
  insulationType: {
    type: String,
  },
  parts: {
    type: Array,
  },
});

const construction = computed(() => {
  console.log("ConstructionCategory computed", props.parts, props.code, props.insulationType);
  if (props.parts) {
    const part = props.parts.find((item) => item.kindKey === props.code);
    if (part) {
      switch (props.insulationType) {
        case "U":
          return { value: part.uValue, category: part.uDescription };
        case "G":
          return { value: part.gValue, category: part.gDescription };
        default:
          return { value: part.rcValue, category: part.rcDescription };
      }
    }
  }
  return undefined;
});
</script>

<template>
  <p v-if="construction">
    <span v-if="construction.value >= 0"> {{ parseFloat(construction.value).toFixed(2) }} ({{ construction.category }}) </span>
    <span v-else> {{ construction.category }} ({{ Math.abs(parseFloat(construction.value).toFixed(2)) }}) </span>
  </p>
  <p v-else>-</p>
</template>
